import { useEffect, useRef, useState } from 'react';
import { BrowserView, isMobile } from "react-device-detect";
import Radium from 'radium';
import { ContainerFullWidth, ContainerRowCentre, SearchInput } from './Containers';

const Search = (props) => {

  const [searchTerms, setSearchTerms] = useState(props.searchTerms);

  // used to give the input focus
  const searchInput = useRef(null);

  useEffect(() => {
    if (props.inputHasFocus) {
      searchInput.current.focus();
    }
  }, [props.inputHasFocus]);

  // these will only be wanted for mobile devices
  var onFocusAction = () => { };
  var onBlurAction = () => { };
  if (isMobile) {
    onFocusAction = () => {
      if (props.setInputHasFocus) {
        props.setInputHasFocus(true);
      }
    }
    onBlurAction = () => {
      props.setSearchTermsCallback(searchTerms);
      if (props.setInputHasFocus) {
        props.setInputHasFocus(false);
      }
    }
  }

  const SubmitButton = (props) => {
    return (
      <button type="submit"
        style={{
          margin: '20px',
          padding: '8px',
          fontSize: '14px',
          width: 'auto',
          backgroundColor: 'rgb(240, 240, 240)',
          border: 'none',
        }} >
        {props.text}
      </button>
    );
  }

  return (
    <form style={props.noSurround ? {} : { padding: '40px' }} onSubmit={(e) => {
      e.preventDefault();
      props.handleSubmit(searchTerms)
    }}>
      <ContainerRowCentre>
        <div key={"searchContainer"} style={props.noSurround ? {
          width: '100%',
        } : {
          border: '1px solid #dee0e4',
          width: '100%',
          maxWidth: '600px',
          borderRadius: '50px',
          padding: '15px',
          backgroundColor: 'white',
          ':hover': {
            boxShadow: '0 0 10px #dee0e4',
          },
        }} >
          <ContainerFullWidth>
            <input type="text" ref={searchInput}
              onChange={(e) => setSearchTerms(e.target.value)}
              value={searchTerms}
              onFocus={onFocusAction}
              onBlur={onBlurAction}
              // key necessary for Radium to work properly and not interrupt compilation
              key={"Actual search box"}
              style={{
                borderWidth: '0px',
                fontSize: '20px',
                color: 'black',
                opacity: '1',
                width: '100%',
                ':focus': {
                  outline: 'none',
                },
              }}
            />
          </ContainerFullWidth>
        </div>
      </ContainerRowCentre>
      {props.showButtons ?
        <BrowserView>
          <ContainerRowCentre>
            <SubmitButton text="Ask Dougal" />
            <SubmitButton text="I do feel lucky, Ted, so I do" />
          </ContainerRowCentre>
        </BrowserView>
        : null}
    </form>
  );
}

export default Radium(Search);