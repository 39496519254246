import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import logoImage from '../assets/images/dougal_main_logo.png';
import collarImage from '../assets/images/collar_32x32.jpg';
import trendingIcon from '../assets/images/trending_icon_24x24.png';
import milkImage from '../assets/images/milk-40x40.jpg';
import dobbyImage from '../assets/images/dobby.jpg';
import leftArrow from '../assets/images/dougal_icon_left_arrow.png';
// import micIcon from '../assets/images/dougal_icon_mic.png';
import { CollarImg, ContainerColumn, ContainerRow,
  ContainerRowCentre, ContainerRowRightJustified,
  Flex0, LinkDiv, MainBody } from '../components/Containers';
import Footer from '../components/Footer';
import Search from '../components/Search';

const HomePage = (props) => {

  //Cant use the hook itself in a non-hook function
  const navigate = useNavigate();
  const [inputHasFocus, setInputHasFocus] = useState(isMobile ? false : true);

  function handleSubmit(searchTerms) {
    props.setSearchTermsCallback(searchTerms);
    navigate("/search");
  }

  const NormalView = () => {
    return (
      <MainBody>
        <Flex0>
          <ContainerRowRightJustified>
            <LinkDiv>
              {/* Inspiration */}
              <a href="http://yourapp.ie">
                YourApp.ie
              </a>
            </LinkDiv>
            <CollarImg src={collarImage} alt="collar pic" key={Math.random()} />
          </ContainerRowRightJustified>
          <ContainerRowCentre>
            <img
              src={logoImage}
              alt="logo"
              width={Math.min(window.innerWidth - 24, 315) + "px"}
              height="auto" />
          </ContainerRowCentre>
          <Search
            handleSubmit={handleSubmit}
            inputHasFocus={inputHasFocus}
            setInputHasFocus={setInputHasFocus}
            searchTerms={props.searchTerms}
            setSearchTermsCallback={props.setSearchTermsCallback}
            showButtons={true} />
          <MobileView>
            <div style={{ padding: '16px' }} >
              <p style={{ fontSize: '20px' }} >Dougal.ie trends</p>
              <Trendings />
            </div>
          </MobileView>
        </Flex0>
        <Footer />
      </MainBody>
    );
  }

  const Trendings = () => {
    return (
      <div style={{ minHeight: 0 , display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'stretch'}}>
        <div style={{ overflowY: 'auto', flex: '1' }}>
          <TrendingSearch text="ecumenical matters" />
          <TrendingSearch
            text="pat mustard"
            subText="deceased milkman"
            image={milkImage} />
          <TrendingSearch text="craggy island covid restrictions" />
          <TrendingSearch text="when is funland returning" />
          <TrendingSearch text="down with this sort of thing" />
          <TrendingSearch text="careful now" />
          <TrendingSearch
            text="vladimir putin"
            subText="git"
            image={dobbyImage} />
        </div>
      </div>
    );
  }

  const TrendingSearch = (props) => {
    return (
      <ContainerRow
      style={{ alignItems: 'center', fontSize: '18px' }}
      onClick={() => handleSubmit(props.text)}>
        <img src={trendingIcon}
          alt=""
          height="24"
          width="24"
          style={{ padding: '12px' }} />
        {props.subText ?
          <ContainerRow style={{}}>
            <ContainerColumn style={{ width: 'auto', flex: 1 }} >
              <span>{props.text}</span>
              <span style={{
                fontSize: '16px',
                paddingTop: '4px',
                color: 'gray'
              }}>
                {props.subText}
              </span>
            </ContainerColumn>
            <div style={{ flex: '0' }}>
              <img src={props.image} alt="" height="40px" width="40px" />
            </div>
          </ContainerRow>
          : props.text}
      </ContainerRow>
    );
  }

  const MobileInputHasFocusView = (props) => {
    return (
      <div onClick={() => setInputHasFocus(false)}>
        <ContainerRow>
          <img src={leftArrow} alt="" width='40px' height='40px' style={{ padding: '16px' }} />
          <Search searchTerms={props.searchTerms}
            setSearchTermsCallback={props.setSearchTermsCallback}
            inputHasFocus={inputHasFocus}
            setInputHasFocus={setInputHasFocus}
            handleSubmit={handleSubmit}
            noSurround={true} />
          {/* <img src={micIcon} alt="" height='40px' width='auto' style={{ padding: '16px' }} /> */}
        </ContainerRow>
        <div style={{ paddingLeft: '16px', paddingRight: '16px' }} >
          <hr />
          <Trendings />
        </div>
      </div>
    );
  }

  return (
    <>
      <BrowserView>
        <NormalView />
      </BrowserView>
      <MobileView>
        {inputHasFocus ?
          <MobileInputHasFocusView
            searchTerms={props.searchTerms}
            setSearchTermsCallback={props.setSearchTermsCallback} />
          : <NormalView />}
      </MobileView>
    </>
  );
}

export default HomePage;