import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ResultsPage from './pages/ResultsPage';

const App = () => {
  const [searchTerms, setSearchTerms] = useState("");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage
          searchTerms={searchTerms}
          setSearchTermsCallback={setSearchTerms} />} />
        <Route path="/search" element={<ResultsPage
        searchTerms={searchTerms}
        setSearchTermsCallback={setSearchTerms} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
