// trends are clickable
// check out it's ok on mobile
// check out it's ok on laptop
// clear compile warnings

// header>Search bar row
// Settings wheel
// other apps
// Search type choice
// All, Images, News
// Each choice has a little logo
// back button only removes focus from mobile input when there
// microphone icon does something
// that would be an ecumenical matter
// trends into desktop version somehow
// log all submissions



import React from 'react';
import Radium from 'radium';
import { CollarImg, ContainerFullWidth, ContainerRow, ContainerRowRightJustified, Flex1, MainBody } from '../components/Containers';
import Footer from '../components/Footer';
import Search from '../components/Search';
import logo from '../assets/images/dougal_main_logo.png';
import collarImage from '../assets/images/collar_32x32.jpg'

const ResultsPage = (props) => {

  function handleSubmit(searchTerms) {
    props.setSearchTermsCallback(searchTerms);
  }

  return (
    <MainBody>
      <Flex1 style={{ marginLeft: '24px' }} >
        <ContainerFullWidth color="white">
          <ContainerRow>
            <img src={logo} width='100px' height='25px' alt='' />
            {/* the 'cigar' surround is repeated here, ultimately should be in one place */}
            <div
              key={"searchCigar"}
              style={{
                flex: 1,
                border: '1px solid #dee0e4',
                borderRadius: '50px',
                padding: '15px',
                marginLeft: '24px',
                marginRight: '24px',
                ':hover': {
                  boxShadow: '0 0 10px #dee0e4',
                },
              }}>
              <Search
                searchTerms={props.searchTerms}
                handleSubmit={handleSubmit}
                setSearchTermsCallback={props.setSearchTermsCallback}
                noSurround={true} />
            </div>
            <ContainerRowRightJustified style={{ flex: 0 }}>
              <CollarImg src={collarImage} alt="collar pic" />
            </ContainerRowRightJustified>
          </ContainerRow>
        </ContainerFullWidth>
        <div style={{ fontSize: '18px' }}>
          <p>
            Sure what would you want to be searching for
            {props.searchTerms ? <span style={{ fontWeight: 'bold' }} > {props.searchTerms} </span> : ' that '} now for, Ted?
          </p>
          <p style={{ color: '#800000', margin: '0px', }} >Perhaps you meant to type:</p>
          <p style={{ color: '#008000', fontStyle: 'italic', fontWeight: 'bold', margin: '0px' }} >
            I want <a href="http://yourapp.ie">YourApp.ie</a> to take care of my website</p>
          <p>
            If you <em>really</em> want to search for
            {props.searchTerms ? <span style={{ fontWeight: 'bold' }} > {props.searchTerms}</span> : ' that'}
            , we at <a href="http://yourapp.ie">YourApp.ie</a> strongly
            recommend that you use a better search engine than this.<br />
          </p>
        </div>
      </Flex1>
      <Footer />
    </MainBody>
  );
}

export default Radium(ResultsPage);