import React from 'react';
import Radium from 'radium';

export const MainBody = (props) => {
  return (
    <div style={{
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      height: '100%',
      width: '100vw',
    }}>
      {props.children}
    </div>
  );
}

export const ContainerFullWidth = (props) => {
  return (<div style={{ backgroundColor: props.color, width: "100%" }}>
    {props.children}
  </div>);
}

export const ContainerColumnCentre = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      gap: '10px',
    }}>
      {props.children}
    </div>
  );
}
export const ContainerRow = (props) => {
  return (<div style={{
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    ...props.style
  }} onClick={props.onClick}>
    {props.children}
  </div>);
}

export const ContainerRowCentre = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
      gap: '10px',
    }}>
      {props.children}
    </div>
  );
}

export const ContainerRowRightJustified = (props) => {
  return (<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'right', ...props.style }}>
    {props.children}
  </div>);
}

export const ContainerColumn = (props) => {
  return (<div style={{
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignContent: 'center',
    ...props.style
  }}>
    {props.children}
  </div>)
}

export const Flex1 = (props) => {
  return (
    <div style={{ flex: '1', ...props.style }}>
      {props.children}
    </div>
  );
}

export const Flex0 = (props) => {
  return (
    <div style={{ flex: '0', ...props.style }}>
      {props.children}
    </div>
  );
}

export const LinkDiv = (props) => {
  return (
    <div style={{
      padding: '20px',
    }}>
      {props.children}
    </div>
  );
}

// Radium facilitates ':hover'
export const CollarImg = Radium((props) => {
  return (
    <div>
      <img
        key={props.key}
        src={props.src}
        alt={props.alt}
        style={{
          margin: '20px 20px 20px 0px',
          height: '32px',
          width: '32px',
          borderRadius: '16px',
          ':hover': {
            boxShadow: '0 0 10px #4d4e50'
          }
        }} />
    </div>
  );
});

export const SomeContent = () => {
  return (<div>
    <div style={{
      height: "50px",
      width: "80px",
      margin: "20px",
      backgroundColor: "aliceblue"
    }} />
  </div>);
}