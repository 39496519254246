import { ContainerRowCentre, LinkDiv } from "./Containers";

const Footer = () => {
  return (
      <div style={{
        backgroundColor: 'rgb(240, 240, 240)',
        paddingTop: '8px',
        flex: '1'
        }}>
        <ContainerRowCentre>
          The world's worst search engine
        </ContainerRowCentre>
        <ContainerRowCentre>
          <LinkDiv>
            <a href="http://yourapp.ie">
              ©2023 YourApp.ie
            </a>
          </LinkDiv>
        </ContainerRowCentre>
      </div>
  );
}

export default Footer;